/* GLOBAL */
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: #ffffff;
  overflow: auto;
}

* {
  box-sizing: border-box;
}

.autocomplete-dropdown {
  border: 1px solid rgb(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 8px 12px;
  margin-top: 8px;
  cursor: pointer;
  box-shadow: 0px 5px 15px #22222214;
}

.location-search-input {
  font-size: 16px;
  opacity: 0.8;
}
.location-search-input::placeholder {
  font-size: 16px;
  opacity: 0.8;
}

.resourcesNavLink {
  text-decoration: none;
  padding: 13px 35px 13px 0px;
  position: relative;
  white-space: nowrap;
}

.resourcesNavLink .resourcesNavLinkText {
  opacity: 0.5;
  color: #000;
}

.resourcesNavLink.active .resourcesNavLinkText {
  opacity: 1;
  color: #000929;
}

.resourcesNavLink.active #borderBottom {
  border-bottom: 3px solid #000929;
  padding-bottom: 10px;
  position: absolute;
  right: 50%;
  left: 0;
}

.scrollBox::-webkit-scrollbar {
  display: none;
}

/*** FONTS ***/
@font-face {
  font-family: figtree_bold;
  src: url(../assets/fonts/Figtree-Bold.ttf);
}

@font-face {
  font-family: figtree_semibold;
  src: url(../assets/fonts/Figtree-SemiBold.ttf);
}

@font-face {
  font-family: figtree_medium;
  src: url(../assets/fonts/Figtree-Medium.ttf);
}

/* CUSTOM  SCROLLBAR */
::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: lightgrey;
}

.filter_container::-webkit-scrollbar {
  width: 4px;
  background: lightgrey;
}

.filter_container::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}

/*** OVERRIDES ***/
input::placeholder {
  font-family: figtree_medium !important;
  font-size: 12px;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: figtree_medium !important;
}

.ql-editor {
  min-height: 150px;
  font-family: figtree_medium !important;
}

.SnackbarItem-message {
  font-family: figtree_medium !important;
}

.fc-toolbar-title {
  color: #182f53;
  font-size: 25px !important;
}

.fc-button-group button {
  text-transform: capitalize !important;
}

.fc-today-button {
  text-transform: capitalize !important;
}

/* MENTIONS */
.mentions_input {
  font-family: figtree_medium !important;
}

.mentions_input__highlighter {
  height: auto;
  min-height: 60px;
  padding: 10px;
  padding-right: 50px;
  transition: height 0.3s;
}

.focused_mentions_input .mentions_input__highlighter {
  min-height: 160px;
}

.mentions_input textarea {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
  padding: 10px;
  padding-right: 50px;
}

.mentions_input__suggestions {
  border-radius: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  background: #fafafa;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(20px);
}

.mentions_input__suggestions__item {
  padding: 5px;
  font-size: 14px;
}

.mentions_input__suggestions__item--focused {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Toast */

.Toastify__toast {
  font-family: figtree_medium !important;
}

.Toastify__toast--error {
  border: 1px solid #eb5757;
  border-radius: 15px !important;
  background: #fae1e2 !important;
}

.Toastify__toast--success {
  border: 1px solid #0a4910 !important;
  border-radius: 15px !important;
  background: #dff9c8df !important;
}


tr:nth-child(even) {
  background-color: rgba(242, 242, 242, 1);
}


.content {
  width: 160%;
}

.list-guideline li {
  font-family: figtree_medium !important;
  margin: 0;
  padding: 0;
  padding-bottom: 6px;
  background-color: #fff;
}

.evidence {
  text-align: left;
  margin-bottom: 10px;
}

.guideline-li {
  font-size: 18px;
}

li::marker {
  color: #2f468c;
}

a {
  cursor: pointer;
  text-decoration: none;
}

ol {
  margin: 0;
}

.terms-li {
  font-size: 15px;
}

.rbc-header {
  font-family: figtree_medium !important;
  background-color: #f4347f;
  color: white;
  padding: 20px 0;
}

.rbc-event {
  font-family: figtree_medium !important;
  background-color: #f4347f;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

/* Calendar Styles */

.rbc-month-header {
  display: flex;
  flex-direction: row;
  height: 60px;
  background-color: "#687B79" !important;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  min-height: 0;
  border-bottom: 1px solid #ddd;
  align-content: center;
  font-size: medium !important;
  font-weight: 400;
  font-family: "figtree_medium" !important;
  background-color: "#687B79" !important;
}
.rbc-event {
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
  color: black !important;
  text-align: left !important;
}
.rbc-day-slot .rbc-event {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: left !important;
}
.rbc-event-label {
  display: none !important;
}
.rbc-event:focus {
  outline: none !important;
}
.rbc-label {
  font-family: "figtree_medium" !important;
}

@media print {
  #pdf-content {
    visibility: visible;
    height: 100%;
    width: 100%;
    overflow: visible !important;
  }
}

/* Slider Css */
.swiper-container {
  height: 300px;
}
.swiper-slide {
  height: 300px;
}
.swiper-pagination { 
  display: none
}

.swiper-button-prev,
.swiper-button-next {
  background-color: #f15b24;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  top: auto; 
  padding: 2px;
}

.swiper-button-prev {
  left: calc(50% - 100px);
}

.swiper-button-next {
  right: calc(50% - 100px); 
}

.swiper-pagination-bullet {
  background-color: #F4347F;
  opacity: 0.3;
  width: 12px;
  height: 12px;
  margin: 0 5px;
}

.swiper-pagination-bullet-active {
  background-color: #F4347F; /* Active dot color */
  opacity: 1;
}
